html, body {
  width: 100%;
  height: 100%;
}

p, span, div, a, li, td, th, code, sub, sup, input, select, pre, h3, h4, h5, h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.2px;
}

h1, h2 {
  font-family: 'Alice', serif;
  font-weight: 600;
  letter-spacing: 0.2px;
}

li{
  list-style: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

// settings for slick dots
.slick-slider{


  .slick-list{
    *{
      outline: none !important;
    }
  }

  .slick-arrow{

    &.slick-next, &.slick-prev{
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      width: 60px;
      min-width: 46px;
      height: 60px;
      display: flex;
      border-radius: 50%;
      background-color: white;
      opacity: .5;
      transition: .3s all;
      top: 50%;

      &:hover{
        opacity: 1;
      }
    }

    &:before{
      display: block;
      content: "";
      width: 12px;
      height: 12px;
      border-left: 4px solid #212121;
      border-top: 4px solid #212121;
      background-color: transparent;
      margin: auto;
    }
    &.slick-next{
      z-index: 30;
      right: 25px;

      &:before{
        transform: rotate(130deg);
      }
    }
    &.slick-prev{
      z-index: 31;
      left: 25px;
      &:before{
        transform: rotate(-45deg);
      }
    }

  }

  //9643
  .slick-dots {
    li {
      margin: 4px 15px;
      button {
        appearance: none;
        padding: 0;
        margin: 0;
        width: 35px;
        height: 4px;
        background: #DCDCDC;

        &:before {
          display: none;
        }
      }

      &.slick-active {
        button {
          background-color: #7D7D7D;
        }
      }
    }

  }
}
