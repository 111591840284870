.modal-mobile {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-mobile__header{
    position: fixed;
    width: 100%;
    top: 0;
    height: 75px;
    background-color: #ffffff;
    z-index: 1001;


    display: flex;
    align-items: center;
    padding-left: 15px;

    h2{
      font-size: 24px;
      max-width: 300px;
      font-family: 'Montserrat', sans-serif;
    }

  }

  .modal-mobile-close {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 300;

  }

  .modal-mobile__footer{
    position: fixed;
    bottom: 0;
    z-index: 1000;
    height: 75px;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;

    button, div{

      width: 100%;
    }
  }

  @media (max-width: 900px){
    padding: 0;
  }


  .modal-mobile-content {
    background-color: white;
    margin: auto;
    position: relative;
    z-index: 400;
    min-height: 100%;
    padding-top: 75px;
    padding-bottom: 75px;
    overflow: hidden;
  }

  .modal-mobile__button-close {
    position: absolute;
    top: 17px;
    right: 17px;
    z-index: 500;
    cursor: pointer;

    svg{
      width: 45px;
      height: 45px;
    }
  }


}