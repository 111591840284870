.header{
  margin-top: 80px;
  //padding-top: 30px;
  height: 63vh;
  background-color: #3E4447;

  @media (max-width: 768px) {
    height: 300px;
  }

  .header-cover__image{
    display: none;
    //background-image: url("/images/mobile_cover.jpeg");
    background-size: cover;
    background-position: center;
    position: absolute;
    top:80px;
    left: 0;
    width: 100%;
    height: 783px;


    @media (max-width: 768px) {
      display: block;
      height: 350px;
    }
  }
  .header__carousel{
    @media (max-width: 768px) {
      display: none;
    }


  .header__background{
    width: 100%;
    height: 70vh;
    //background-image: url("/images/delmar.jpg");
    background-size: cover;
    background-position: center;
   }
  }



}