.footer {
  position: relative;
  height: 520px;
  overflow: hidden;
  padding: 25px;


  &__address{
    color: white;

    a{

    text-decoration: none;
    }
  }

  span, div, a {
    color: white;
  }

  .footer__wrapper {
    position: relative;
    z-index: 4;
    padding-top: 10px;
    height: 100%;


    .footer__column {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .footer__blocks {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 20px;

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
      }

      .footer__right {
        //padding-bottom: 20px;
      }

      .footer__left {

      }

      .footer__left, .footer__right {

        span {
          font-size: 16px;
          font-weight: 600;
          line-height: 25px;
          color: white;
        }

        div {
          color: white;
          max-width: 400px;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 5px;

        }

        .footer__phone {
          line-height: 28px;
          font-size: 18px;
          color: white;
          margin-bottom: 32px;
          text-decoration: none;

        }

        .footer__icons {
          margin-top: 32px;
          margin-bottom: 32px;
          //max-width: 75px;
          display: flex;
          align-items: center;

          a{
            display: block;
            margin-right: 20px;
          }
        }
      }

      .footer__right {
        //text-align: right;
        //margin-left: auto;

        //@media (max-width: 900px) {
        //  text-align: left;
        //  margin-left: 0;
        //}
      }
    }

    .footer__bottom {
      border-top: 1px solid rgba(255, 255, 255, 0.33);
      padding-top: 25px;
      padding-bottom: 25px;
      grid-template-columns: 1fr 1fr;

      display: grid;
      grid-row-gap: 20px;

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
      }

      .footer__info {
        color: white;
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;

        &:last-child{
          margin-left: auto;
          text-align: right;

          @media (max-width: 900px) {
            margin-left: 0;
            text-align: left;
          }
        }


        a {
          text-decoration: none;
          color: white;
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;
        }
      }
    }
  }

  .footer__background {
    position: absolute;
    background-size: cover;
    background-position: left center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    //filter: blur(5px);
  }

  .footer__shadow {
    position: absolute;
    z-index: 2;
    height: 100%;

    &.left {
      background: linear-gradient(90.18deg, #1B2118 0.13%, rgba(27, 33, 24, 0) 99.81%);
      opacity: 0.5;
      width: 40%;
      left: 0;
      top: 0;
    }

    &.right {
      height: 100%;
      background: linear-gradient(90.18deg, #1B2118 0.13%, rgba(27, 33, 24, 0) 99.81%);
      transform: rotate(-180deg);
      width: 40%;
      top: 0;
      right: 0;
    }

    &.top {
      background: linear-gradient(179deg, #1B2118 0.13%, rgba(27, 33, 24, 0) 99.81%);
      opacity: 0.6;
      //transform: rotate(90deg);
      height: 200px;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

}