.services {
  margin-bottom: 30px;

  .services__wrapper {
    flex-wrap: wrap;
    padding: 0 25px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 70px;

    @media (max-width: 1100px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 50px;
    }
    @media (max-width: 900px) {
    //  grid-template-columns: 1fr;
      grid-gap: 15px;
    }


    .services__item {
      text-decoration: none;
      position: relative;

      .services__image {
        background-size: cover;
        height: 217px;
        width: 100%;
        transition: .3s all;

      }

      @media (max-width: 900px){
        &:before{
          content: "";
          display: block;
          width: 100%;
          background: linear-gradient(180.66deg, rgba(0, 0, 0, 0) 19.4%, rgba(0, 0, 0, 0.8) 85.87%);
          height: 80%;
          position: absolute;
          bottom: 0;
          z-index: 2;
        }
      }



      .services__text {


        @media (max-width: 900px) {
          position: absolute;
          bottom: 25px;
          left: 10px;
          z-index: 3;
        }

        h2 {
          color: #172B4D;
          font-weight: 600;
          font-size: 24px;
          line-height: 33px;
          margin: 7px 0 11px 0;

          @media (max-width: 900px) {
          color:white;
            font-family: 'Montserrat', sans-serif;
            font-weight: normal;
            font-size: 17px;
            line-height: 19px;
          }
        }

        .services__description {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #8993A4;

          @media (max-width: 900px) {
            display: none;
          }
        }
      }
    }
  }
}