// MOBILE
.modal-room-mobile {
  width: 100%;
  max-width: 100%;

  .slick-track {
    margin-left: 60px;
  }

  .modal-room-mobile__image {
    height: 250px;
    background-size: cover;
    background-position: center;
    transform: scale(0.98);
  }

  .modal-room-mobile__descr {
    padding: 15px;
    font-size: 14px;
    line-height: 19px;
    color: #444444;
    //margin-bottom: 20px;

    p, ul, div{
      margin-bottom: 10px;
    }
  }

  .modal-room-mobile__prices {
    padding: 15px;

    h4 {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      color: #212121;
      margin-bottom: 13px;

    }

    ul {
      li {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 4px;

        .modal-room-mobile__date {
          font-size: 14px;
          font-weight: 600;
          color: #212121;
          line-height: 18px;

        }

        .modal-room-mobile__currency {
          font-size: 16px;

          color: #212121;
          font-weight: 600;
        }

        .modal-room-mobile__summary {
          font-size: 21px;
          font-weight: 700;

          color: #212121;
        }
      }
    }
  }

  .modal-room-mobile__properties {
    padding: 15px;

    h4 {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      color: #212121;
      margin-bottom: 13px;
    }

    ul {
      li {

        font-size: 14px;
        line-height: 19px;
        color: #666666;
        margin-bottom: 2px;
        padding-right: 5px;
      }

    }
  }

  .modal-room-mobile__bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: red;
    height: 50px;
    width: 100%;
  }
}

// DESKTOP
.modal-room {
  width: 1366px;
  height: 585px;
  max-width: 100%;
  background-color: white !important;

  .slick-track:before, .slick-track:after {
    display: block;
  }

  .modal-room__wrapper {
    display: flex;
    width: 100%;

    .modal-room__data {
      position: relative;
      //overflow-y: hidden;
      background-color: white;
      width: 100%;
      max-width: 100%;
      overflow-y: auto;
      //height: 100%;
      padding-bottom: 80px;

      .modal-room__text {
        padding: 30px 39px 0 39px;

        .modal-room__title {
          font-size: 34px;
          line-height: 36px;
          color: #212121;
          margin-bottom: 10px;
          margin-left: -2px;
          font-family: 'Montserrat', sans-serif;
        }

        .modal-room__description {
          max-width: 355px;
          font-size: 14px;
          line-height: 19px;
          color: #444444;
          margin-bottom: 20px;

          p, ul, div{
            margin-bottom: 10px;
          }
        }
      }

      .modal-room__properties {
        //height: 153px;
        background: #F4F5F7;
        padding: 13px 0 13px 39px;
        width: 100%;

        h4 {
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          color: #212121;
          margin-bottom: 5px;
        }

        ul {
          display: flex;
          flex-wrap: wrap;

          li {
            flex: 0 0 50%;
            font-size: 14px;
            line-height: 19px;
            color: #666666;
            margin-bottom: 2px;
            padding-right: 5px;
          }
        }
      }

      .modal-room__prices {
        padding: 13px 0 15px 39px;
        width: 100%;

        h4 {
          margin-left: -2px;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          color: #212121;

        }

        ul {
          li {
            display: flex;
            justify-content: space-between;
            max-width: 380px;
            align-items: flex-end;

            .modal-room__date {
              font-size: 18px;
              font-weight: 600;
              color: #212121;
              line-height: 24px;

            }

            .modal-room__currency {
              font-size: 18px;

              color: #212121;
              font-weight: 600;
            }

            .modal-room__summary {
              font-size: 24px;
              font-weight: 700;

              color: #212121;
            }
          }
        }
      }

      // навігація
      .modal-room__nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 40px 10px 40px;

        position: absolute;
        bottom: 0;
        background-color: white;
        width: 100%;

        a{
         text-decoration: none;
        }

        .modal-room__prev {
          cursor: pointer;
          max-width: 100px;
          text-align: left;
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          color: #212121;
          position: relative;
          transition: .3s all;
          opacity: .78;

          &:hover {
            opacity: 1;
            i {
              border-color: #34B288;
            }
          }

          i {
            transition: .3s all;
            position: absolute;
            width: 12px;
            height: 12px;
            left: -15px;
            top: 14px;
            transform: rotate(45deg);
            display: block;
            border-bottom: 3px solid #212121;
            border-left: 3px solid #212121;
          }
        }

        .modal-room__next {
          position: relative;
          cursor: pointer;

          max-width: 100px;
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          color: #212121;
          text-align: right;
          opacity: .78;
          white-space: pre-wrap;

          &:hover {
            opacity: 1;
            i {
              border-color: #34B288;
            }
          }

          i {
            transition: .3s all;
            position: absolute;
            width: 12px;
            height: 12px;
            right: -15px;
            top: 14px;
            transform: rotate(-135deg);
            display: block;
            border-bottom: 3px solid #212121;
            border-left: 3px solid #212121;
          }
        }
      }

    }
  }

}