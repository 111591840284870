@import "../../styles/variable";

.menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 84px;
  width: 100%;
  padding-top: 22px;
  z-index: 90;

  background-color: $color-header;


  &__phone{
    font-size: 13px;
    margin-bottom: 10px;
    display: block;
    color: white;

  }
  &__phones{
      position: absolute;
    right: 20px;
    top:20px;

    @media (max-width: 1645px) {
      display: none;
    }
  }

  .menu__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .menu__logo{
      cursor: pointer;
    }


    .menu__list {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media (max-width: 1124px) {
        display: none;
      }

      .menu__button{
        margin-left: 30px;
      }

      ul {
        display: flex;

        // scroll functionality
        a {
          &.active {
            li {
              color: #4BCA85;
            }
          }
        }
      }
      .menu__item {
        list-style: none;
        margin-left: 30px;
        color: white;
        cursor: pointer;

        &:hover {
          color: #4BCA85;
        }
      }
    }
  }
}

// mobile menu //
.mobile__menu {
  position: fixed;
  width: 100%;
  background-color: white;
  top: 0;
  left: 0;
  height: 0;
  transition: 0.3s all;
  z-index: 99;
  overflow: hidden;

  &.open {
    height: 100%;
  }


  .mobile__header{
    height: 84px;
    background-color: $color-header;
    display: flex;
    align-items: center;
    padding-left: 20px;

  }

  .mobile__reservation {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .mobile__item-list {
    position: relative;
    z-index: 80;
    padding-top: 5px;

    ul {
      li {
        padding: 15px 20px 14px 20px;
        color: #666666;
        font-size: 21px;

      }


      a{
        &:nth-child(even) {
          li{
            background-color: #F6F6F6;
          }
        }
      }
    }
  }
}


